.info {
  border-top: 1px solid #181818;
  border-bottom: 1px solid #181818;
  display: flex;
  justify-content: space-between;
  .box {
    padding: 20px;
    border-left: 1px solid #181818;
    text-align: center;
    width: 100%;
    span {
      color: #899098;
      font-size: 0.875rem;
      font-weight: 500;
    }
    h1 {
      margin: 0;
      font-family: IBM Plex Mono;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}
.inputWraper {
  margin: 30px 0;
  .inputArea {
    border-radius: 3.125rem;
    border: 1px solid #181818;
    background: #000;
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-between;
    align-items: center;
    .polygonIcon {
      padding: 10px;
      border-right: 1px solid #181818;
      display: flex;
      img {
        width: 100%;
      }
    }
    .input {
      width: 100%;
      background: transparent;
      border: none;
      height: 34px;
      color: #ffffff;
      padding: 0 10px;
      &:focus {
        outline: none;
      }
    }
    .maxButton {
      background: transparent;
      border: none;
      height: 50px;
      color: #fff279;
      font-size: 1rem;
      font-weight: 530;
      border-left: 1px solid #181818;
      padding: 10px;
      cursor: pointer;
    }
  }
  .infoArea {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #899098;
    font-family: IBM Plex Mono;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 75%;
    margin: auto;
    margin-top: 5px;
    padding: 0 5px;
  }
}

.connectButton {
  border-radius: 0.75rem;
  border: 1px solid #fff;
  background: #3b5aff;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  width: 80%;
  margin: auto;
  border: none;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  transition: 250ms;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
