.box {
  width: 100%;
  border-radius: 26px;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: #101010;
  .header {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 530;
    display: flex;
    padding: 20px;
    justify-content: space-between;
    img {
      margin-right: 10px;
    }
    span {
      display: flex;
      align-items: center;
    }
    .tabView {
      display: flex;
      align-items: center;
      border-radius: 3.0625rem;
      border: 1px solid rgba(255, 255, 255, 0.08);
      background: linear-gradient(0deg, #000 0%, #000 100%), #000;
      width: 50%;
      padding: 3px;
      gap: 10px;
      justify-content: space-between;
      .button {
        border-radius: 3.0625rem;
        height: 44px;
        line-height: 44px;
        width: 50%;
        text-align: center;
        cursor: pointer;
        transition: 300ms;
        &.active {
          background: #181818;
        }
      }
    }
  }
  .main {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid #181818;
    border-bottom: 1px solid #181818;
    .bagIcon {
      height: 250px;
    }
    .connectButton {
      border-radius: 0.75rem;
      border: 1px solid #fff;
      background: #3b5aff;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 600;
      width: 80%;
      margin: auto;
      border: none;
      margin-top: 20px;
      height: 54px;
      line-height: 54px;
      cursor: pointer;
      transition: 250ms;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.7;
      }
    }
    .alertBox {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #899098;
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 8px;
      background: #171717;
      width: 80%;
      margin: auto;
      margin-top: 40px;
      padding: 1rem;
      img {
        margin-right: 5px;
        // height: 0.875rem;
      }
    }
  }
  .footer {
    padding: 10px;
    color: #62676d;
    font-size: 0.875rem;
    font-weight: 530;
    text-align: center;
    span {
      color: #899098;
      font-size: 0.875rem;
      font-weight: 530;
    }
  }
}

@media only screen and (max-width: 900px) {
  .box {
    margin-top: 20px;
    border-radius: 0;
    .header {
      flex-direction: column;
      padding: 10px;
      .tabView {
        display: flex;
        align-items: center;
        border-radius: 3.0625rem;
        border: 1px solid rgba(255, 255, 255, 0.08);
        background: linear-gradient(0deg, #000 0%, #000 100%), #000;
        width: 100%;
        padding: 3px;
        margin-top: 10px;
        gap: 10px;
        justify-content: space-between;
        .button {
          border-radius: 3.0625rem;
          height: 44px;
          line-height: 44px;
          width: 50%;
          text-align: center;
          cursor: pointer;
          transition: 300ms;
          &.active {
            background: #181818;
          }
        }
      }
    }
    .main {
      padding-bottom: 40px;
      display: flex;
      flex-direction: column;
      text-align: center;
      border-top: 1px solid #181818;
      border-bottom: 1px solid #181818;
      .bagIcon {
        height: 250px;
      }
      .connectButton {
        border-radius: 0.75rem;
        border: 1px solid #fff;
        background: #3b5aff;
        color: #fff;
        font-size: 1.125rem;
        font-weight: 600;
        width: 80%;
        margin: auto;
        border: none;
        margin-top: 20px;
        height: 54px;
        line-height: 54px;
        cursor: pointer;
        transition: 250ms;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 10px;
        }
        &:hover {
          opacity: 0.7;
        }
      }
      .alertBox {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #899098;
        font-size: 0.875rem;
        font-weight: 500;
        border-radius: 8px;
        background: #171717;
        width: 80%;
        margin: auto;
        margin-top: 40px;
        padding: 1rem;
        img {
          margin-right: 5px;
          // height: 0.875rem;
        }
      }
    }
    .footer {
      padding: 10px;
      color: #62676d;
      font-size: 0.875rem;
      font-weight: 530;
      text-align: center;
      span {
        color: #899098;
        font-size: 0.875rem;
        font-weight: 530;
      }
    }
  }
}
