:root {
  font-size: 16px;
}
@font-face {
  font-family: "General Sans Variable";
  src: url("/assets/fonts/GeneralSans-Variable.ttf") format("truetype");
  font-weight: 100 900;
}
@font-face {
  font-family: "IBM Plex Mono";
  font-display: swap;
  src: local("IBMPlexMono-Medium"),
    url(./assets/fonts/IBMPlexMono-Medium.ttf) format("truetype");
}
body {
  margin: 0;
  font-family: "General Sans Variable";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  margin: auto;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  width: 100%;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1140px;
  }
  .container-xl,
  .container-xxl {
    max-width: 1240px;
  }
}

.mainSection {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  place-items: center;
  min-height: calc(100vh - 72px);
}

@media only screen and (max-width: 900px) {
  :root {
    font-size: 14px;
  }
  .mainSection {
    grid-template-columns: repeat(1, 1fr);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
}

.sliderWraper {
  width: 80%;
  margin: 25px auto;
  .sliderInfo {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: #899098;
    font-family: IBM Plex Mono;
    font-size: 0.75rem;
    font-weight: 500;
  }
}

#range3,
#stakeSlider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 8px;
  pointer-events: none;
  // background: linear-gradient(to right, #31c859 60%, #fff 60%);
}

#range3::-webkit-slider-thumb,
#stakeSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 30px;
  width: 30px;
  background: transparent;
  background-size: cover;
  border-radius: 50%;
}

#range3::-moz-range-thumb,
#stakeSlider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 30px;
  width: 30px;
  background: transparent;
  background-size: cover;
  border-radius: 50%;
}

#range3::-webkit-slider-thumb {
  background-image: url("/assets/buySlider.svg");
}

#range3::-moz-range-thumb {
  background-image: url("/assets/buySlider.svg");
}

#stakeSlider::-webkit-slider-thumb {
  background-image: url("/assets/stakeSlider.svg");
}

#stakeSlider::-moz-range-thumb {
  background-image: url("/assets/stakeSlider.svg");
}
