.info {
  margin: 60px 0;
  border-top: 1px solid #181818;
  border-bottom: 1px solid #181818;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .left {
    border-right: 1px solid #181818;
    .hr_text {
      border: 0;
      font-size: 0.875rem;
      height: 1.5em;
      line-height: 1em;
      position: relative;
      text-align: center;
      &:before {
        content: "";
        background: #181818;
        position: absolute;
        left: 0;
        top: 50%;
        width: 100%;
        height: 1px;
      }
      &:after {
        content: attr(data-content);
        position: relative;
        color: #5e6771;
        font-size: 0.875rem;
        font-weight: 500;
        background: #101010;
        line-height: 1.2rem;
        padding: 0 10px;
      }
    }
    .upper,
    .lower {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #899098;
      font-size: 1rem;
      font-weight: 500;
      img {
        margin-right: 10px;
      }
    }
    .upper {
      padding-top: 10px;
    }
    .lower {
      padding-bottom: 10px;
    }
  }
  .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      font-family: IBM Plex Mono;
      color: #fff;
      font-size: 1.4rem;
      font-weight: 600;
      sub {
        font-size: 0.875rem;
        font-weight: 600;
        margin-left: 2px;
        vertical-align: baseline;
      }
    }
    p {
      margin: 0;
      color: #899098;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}

.connectedButton {
  border-radius: 12px;
  border: 1px solid #fff;
  opacity: 0.66;
  background: #232530;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  width: 80%;
  margin: auto;
  height: 53px;
  line-height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
}
