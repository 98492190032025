.info {
  border-top: 1px solid #181818;
  border-bottom: 1px solid #181818;
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
  .box {
    padding: 20px;
    border-left: 1px solid #181818;
    text-align: center;
    width: 100%;
    span {
      color: #899098;
      font-size: 0.875rem;
      font-weight: 500;
    }
    h1 {
      margin: 0;
      font-family: IBM Plex Mono;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}

.info2 {
  display: flex;
  justify-content: space-between;
  justify-content: center;
  gap: 30px;
  .box {
    padding: 10px 30px;
    border-radius: 0.3125rem;
    background: #161616;

    span {
      color: #899098;
      font-size: 0.875rem;
      font-weight: 500;
    }
    h1 {
      margin: 0;
      font-family: IBM Plex Mono;
      color: #fff;
      font-size: 1.25rem;
      font-weight: 600;
    }
  }
}

.connectButton {
  border-radius: 0.75rem;
  border: 1px solid #fff;
  background: #3b5aff;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 600;
  width: 80%;
  margin: auto;
  border: none;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  transition: 250ms;
  &:hover {
    opacity: 0.7;
  }
  &:disabled {
    border-radius: 0.75rem;
    border: 1px solid transparent;
    background: rgba(39, 41, 51, 0.4);
    color: rgba(255, 255, 255, 0.1);
    pointer-events: none;
  }
}

.alertBox {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #899098;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 8px;
  background: #171717;
  width: 80%;
  margin: auto;
  margin-top: 25px;
  padding: 1rem;
  img {
    margin-right: 5px;
    // height: 0.875rem;
  }
}
