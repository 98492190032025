nav {
  border-bottom: 1px solid #373737;
  .navBar {
    display: flex;
    height: 74px;
    justify-content: space-between;
    align-items: center;
    a {
      text-decoration: none;
      border-radius: 43px;
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      font-size: 1.1rem;
      font-weight: 500;
      border: none;
      padding: 10px 20px;
      cursor: pointer;
      transition: 250ms;
      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
