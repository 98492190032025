h1 {
  color: #fffce1;
  font-size: 3rem;
  font-weight: 500;
}
.leftBanner {
  position: relative;
  p {
    padding: 20px;
    position: absolute;
    bottom: 10px;
    color: #fffce1;
    font-size: 1rem;
    font-weight: 500;
    width: 61%;
  }
}

@media only screen and (max-width: 900px) {
  .leftPanel {
    display: none;
  }
}
